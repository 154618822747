<template>
    <div>
        <b-container>
            <b-row>
                <b-col sm="12">
                    <div class="joinTitle">
                        <h2><b>{{ data.daftarTitle }}</b></h2>
                    </div>
                </b-col>
                <b-col sm="12">
                    <div class="joinDesc">
                        <p>{{ data.daftarDesc }}</p>
                    </div>
                </b-col>
                <b-col sm="12">
                    <div class="joinTitle">
                        <h4>{{ data.bergabungTitle }}</h4>
                    </div>
                </b-col>
                <b-col sm="12">
                    <div class="buttonJoin for_desktop">
                        <b-row>
                            <!-- <b-col>
                            <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_BASIC'"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/basic.png" width="100%" alt="Gabung Bisnisonline BGS Basic" desc="Gabung Bisnisonline BGS Basic"></a>
                            <div class="descButtonJoin"><h4>{{data.bergabungDesc1}}</h4></div>
                        </b-col> -->
                            <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_SILVER'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/silver.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Silver"
                                        desc="Gabung Bisnisonline BGS Silver"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc2 }}</h4>
                                </div>
                            </b-col>
                            <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_GOLD'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/gold.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Gold"
                                        desc="Gabung Bisnisonline BGS Gold"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc3 }}</h4>
                                </div>
                            </b-col>
                            <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_PLATINUM'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/platinum.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Platinum"
                                        desc="Gabung Bisnisonline BGS Platinum"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc4 }}</h4>
                                </div>
                            </b-col>
                            <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_DIAMOND'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/stardiamond.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Star Diamond"
                                        desc="Gabung Bisnisonline BGS Star Diamond"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc5 }}</h4>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <b-col sm="12">
                    <div class="buttonJoin for_tablet">
                        <b-row>
                            <!-- <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_BASIC'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/basic.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Basic"
                                        desc="Gabung Bisnisonline BGS Basic"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc1 }}</h4>
                                </div>
                            </b-col> -->
                            <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_SILVER'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/silver.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Silver"
                                        desc="Gabung Bisnisonline BGS Silver"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc2 }}</h4>
                                </div>
                            </b-col>
                            <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_GOLD'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/gold.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Gold"
                                        desc="Gabung Bisnisonline BGS Gold"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc3 }}</h4>
                                </div>
                            </b-col>
                            <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_PLATINUM'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/platinum.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Platinum"
                                        desc="Gabung Bisnisonline BGS Platinum"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc4 }}</h4>
                                </div>
                            </b-col>
                            <b-col>
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_DIAMOND'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/stardiamond.png"
                                        width="100%" alt="Gabung Bisnisonline BGS Star Diamond"
                                        desc="Gabung Bisnisonline BGS Star Diamond"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc5 }}</h4>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <b-col sm="12">
                    <div class="buttonJoin for_mobile">
                        <b-row>
                            <!-- <b-col sm="12">
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_BASIC'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/basic.png"
                                        width="70%" alt="Gabung Bisnisonline BGS Basic"
                                        desc="Gabung Bisnisonline BGS Basic"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc1 }}</h4>
                                </div>
                            </b-col> -->
                            <b-col sm="12">
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_SILVER'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/silver.png"
                                        width="70%" alt="Gabung Bisnisonline BGS Silver"
                                        desc="Gabung Bisnisonline BGS Silver"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc2 }}</h4>
                                </div>
                            </b-col>
                            <b-col sm="12">
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_GOLD'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/gold.png"
                                        width="70%" alt="Gabung Bisnisonline BGS Gold"
                                        desc="Gabung Bisnisonline BGS Gold"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc3 }}</h4>
                                </div>
                            </b-col>
                            <b-col sm="12">
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_PLATINUM'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/platinum.png"
                                        width="70%" alt="Gabung Bisnisonline BGS Platinum"
                                        desc="Gabung Bisnisonline BGS Platinum"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc4 }}</h4>
                                </div>
                            </b-col>
                            <b-col sm="12">
                                <a
                                    v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId=' + username + '&memberPackageId=PACKAGE_DIAMOND'"><img
                                        src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/stardiamond.png"
                                        width="70%" alt="Gabung Bisnisonline BGS Star Diamond"
                                        desc="Gabung Bisnisonline BGS Star Diamond"></a>
                                <div class="descButtonJoin">
                                    <h4>{{ data.bergabungDesc5 }}</h4>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>

        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            bahasa: 'id',
            data: {
                daftarTitle: null,
                daftarDesc: null,
                bergabungTitle: null,
                bergabungDesc1: null,
                bergabungDesc2: null,
                bergabungDesc3: null,
                bergabungDesc4: null,
                bergabungDesc5: null
            },
            username: null
        }
    },
    mounted() {
        this.username = window.location.pathname.replace("/", "");
        if (this.username == '') {
            this.username = 'happy'
        }

        this.bahasa = localStorage.getItem('bahasa')
        axios.get(`../../lang_${this.bahasa}.json`).then(response => {
            //   console.log(response.data.data[11].daftar[0].daftarTitle)
            this.data.daftarTitle = response.data.data[11].daftar[0].daftarTitle
            this.data.daftarDesc = response.data.data[11].daftar[0].daftarDesc

            this.data.bergabungTitle = response.data.data[12].bergabung[0].bergabungTitle
            this.data.bergabungDesc1 = response.data.data[12].bergabung[0].bergabungDesc1
            this.data.bergabungDesc2 = response.data.data[12].bergabung[0].bergabungDesc2
            this.data.bergabungDesc3 = response.data.data[12].bergabung[0].bergabungDesc3
            this.data.bergabungDesc4 = response.data.data[12].bergabung[0].bergabungDesc4
            this.data.bergabungDesc5 = response.data.data[12].bergabung[0].bergabungDesc5
        })
    }
}
</script>
